import React from 'react'
import { Helmet } from 'react-helmet'
import { Flexbox, Text, Theme } from '@sonika-se/ui-kit'
import { createUseStyles } from 'react-jss'

import Layout from '../components/Layout'
import NewsletterForm from '../components/NewsletterForm'

import assistansstodOG from '../assets/assistansstod-opengraph.png'

const useStyles = createUseStyles<Theme, 'main' | 'content' | 'form' | 'heading' | 'image' | 'text'>({
  content: {
    flex: 1,
  },
  form: {
    marginTop: '2rem !important',
    width: '50%',
    ['@media (max-width: 1200px)']: {
      width: '100%',
    },
  },
  heading: {
    fontSize: '4rem',
    maxWidth: '25ch',
    ['@media (max-width: 768px)']: {
      fontSize: '2rem',
      maxWidth: 'none',
    },
  },
  image: {
    boxShadow: '24px 16px 64px 0 rgba(0, 0, 0, 0.08)',
    maxWidth: '25vw',
    ['@media (max-width: 1600px)']: {
      display: 'none',
    },
  },
  main: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    padding: '2rem',
    ['@media (max-width: 768px)']: {
      padding: '1rem',
    },
  },
  text: {
    fontSize: '1.5rem',
    maxWidth: '60ch',
    ['@media (max-width: 768px)']: {
      fontSize: '1rem',
    },
  },
})

const Home: React.FC = () => {
  const classes = useStyles()

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="description" content="Nästa generations plattform för personal, schemaläggning, tidrapportering." />
        <link href="/assets/icons/assistansstod.ico" rel="icon" type="image/x-icon" />
        <link href="/assets/icons/assistansstod-32x32.png" rel="icon" sizes="32x32" type="image/png" />
        <link href="/assets/icons/assistansstod-180x180.png" rel="icon" sizes="180x180" type="image/png" />
        <link href="/assets/icons/assistansstod-192x192.png" rel="icon" sizes="192x192" type="image/png" />
        <link href="/assets/icons/assistansstod-512x512.png" rel="icon" sizes="512x512" type="image/png" />
        <meta property="og:title" content="Assistansstöd – verksamhetssystemet för personlig assistans" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.assistansstod.se/" />
        <meta property="og:image" content={"https://assistansstod.se" + assistansstodOG} />
        <title>Assistansstöd från Sonika - ett verksamhetssystem för personlig assistans</title>
      </Helmet>
      <Layout></Layout>
    </>
  )
}

export default Home
